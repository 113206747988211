<template>
  <div>
    <van-nav-bar title="领取优惠卷" left-arrow @click-left="$router.back(-1)" fixed placeholder />
    <!--导航栏--->
    <van-empty description="暂无优惠券" v-if="!Couponlist.length" />
    <div class="coupon-lists" v-else>
      <div class="list" v-for="(item, index) in this.Couponlist" :key="index">
        <div class="list-discount">
          <div>
            <span v-if="item.discount">￥<span style="font-size: 16px; margin: 0 4px">{{ item.discount }}折劵</span></span>
            <span v-else>￥<span style="font-size: 16px; margin: 0 4px">{{
                item.reduceAmount
                }}</span></span>
            <span style="font-size: 14px">订单满{{ item.premiseAmount }}可用</span>
          </div>
          <div class="list-discount-text" @click="getItemCoupon(item)">
            {{item.checkData ? '已领取' : '立即领取'}}
          </div>
        </div>

        <div class="date" v-if="item.expireType == '1' && item.checkData">
          有效日期：<span>{{ item | validBeginTime }}</span>
        </div>
        <!-- 2 固定时间段 -->
        <div class="date" v-if="item.expireType == '2'">
          有效日期：<span>{{ `${item.validBeginTime}至${item.validEndTime}` }}</span>
        </div>
        <div class="date" v-if="item.expireType == '1' && item.checkData == false">
          <span style="color: transparent;">优惠券</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getCoupon, postCoupon, fetchCoupon } from "@/api/coupon/coupon";
  export default {
    data() {
      return {
        value: 1,
        checked: true,
        Couponlist: {
          records: [],
        },
        index: "",
      };
    },
    created() {
      this.spuId = this.$route.query.spuId;
      this.Coupontype();
    },
    mounted() { },
    filters: {
      validBeginTime(item) {
        if (item.getTime != '' && item.getTime != null) {
          const getTime = item.getTime
          const endTIme = new Date(item.getTime.replace(/-/g, '/'))
          const validDays = item.validDays
          endTIme.setDate(endTIme.getDate() + Number(validDays))
          const year = endTIme.getFullYear()
          let month = endTIme.getMonth() + 1
          let date = endTIme.getDate()
          let hour = endTIme.getHours()
          let minute = endTIme.getMinutes()
          let second = endTIme.getSeconds()
          if (month < 10) {
            month =  '0' + month
          }
          if (date < 10) {
            date =  '0' + date
          }
          if (hour < 10) {
            hour =  '0' + hour
          }
          if (minute < 10) {
            minute =  '0' + minute
          }
          if (second < 10) {
            second =  '0' + second
          }
          const JieShuTime = `${year}-${month}-${date} ${hour}:${minute}:${second}`
          console.log(JieShuTime, '结束时间')
          return `${getTime}至${JieShuTime}`
        }
      }
      // validEndTime(item) {
      //   if (item.validBeginTime) {
      //     return item.validEndTime
      //   } else {
      //     let validDays = Number(item.validDays) * 24 * 60 * 60 * 1000
      //     var date1 = new Date(item.updateTime);
      //     var time1 = date1.getTime();
      //     var time2 = time1 + validDays

      //     var date = new Date(time2);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      //     var Y = date.getFullYear() + '-';
      //     var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      //     var D = date.getDate() + ' ';
      //     var h = date.getHours() + ':';
      //     var m = date.getMinutes() + ':';
      //     var s = date.getSeconds();
      //     let time3=Y + M + D + h + m + s;
      //     return time3
      //   }
      // }
    },
    methods: {
      onClickLeft() {
        this.$router.back(-1)
      },
      // 领取劵 然后缓存去提交订单时用
      getItemCoupon(item) {
        if (!item.checkData) {
          fetchCoupon([item.id]).then(res => {
            if (res.data.code === 0) {
              console.log(res);
              this.Coupontype();
            }
          })
        } else {
          console.log('领取过了');
        }
      },
      Coupontype() {
        let params = { spuId: this.$route.query.shopId };
        getCoupon(params).then((res) => {
          this.Couponlist = res.data.data.shopInfo
        });
      },
      Coupontypepost(item) {
        let params = { couponId: item.id };
        postCoupon(params).then((res) => {
          if (res.data.code == 0) {
            this.$toast('领取成功')
            this.Coupontype(params);
          }
        });
      },
    },
    watch: {},
    computed: {},
  };
</script>
<style lang="less" scoped>
  .sureorder-one {
    position: absolute;
    font-size: 10px;
    bottom: 3px;
    padding: 0 0 2px 2px;
    color: #fff;
  }

  .name-one {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2px 0 0 50px;
    /* border: 1px solid #fff; */
  }

  .coupon-header {
    position: relative;
    left: 0;
    right: 0;
    top: 44px;
    height: 100px;
    padding: 9px 16px 0 16px;
    /* margin-top: 10px; */
  }

  .evers {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    top: -108px;
  }

  .receive {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffc656;
    font-size: 13px;
    color: #c17412;
  }

  .coupon-one {
    /* border: 1px solid red; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
    margin-right: 5px;
  }

  .coupon-footer {
    margin-top: 40px;
  }

  .coupon-lists {
    .list {
      margin: 12px 20px 0 21px;
      background: linear-gradient(to left, #e82063, #f96485);
      color: #fff;
      position: relative;
      border-radius: 8px;

      .list-discount {
        padding-top: 10px;
        padding-left: 10px;
        display: flex;
        justify-content: space-between;

        .list-discount-text {
          padding-right: 13px;
          font-size: 12px;
          align-self: flex-end;
        }
      }

      .list-pic {
        width: 50px;
        height: 50px;
        position: absolute;
        bottom: 0;
        right: 4px;
      }

      .date {
        padding: 10px 0 12px 10px;
      }
    }
  }
</style>